import { gravatar, initials } from './avatar.api';

function GravatarViewModel (params) {
    const self = this;

    self.name = ko.unwrap(params.name);
    self.email = ko.unwrap(params.email);
    self.login = ko.unwrap(params.login);
    self.hasGravatar = ko.observable(false);
    self.gravatarUrl = ko.observable('');
    self.title = (self.name
        ? self.name + '\n' + self.email
        : self.email) +
             (self.login ? `\n(${self.login})` : '');
    self.fallbackComponent = ko.unwrap(params.fallbackComponent);

    self.showGravatar = () => {
        if (self.email) {
            gravatar.get(self.email)
                .then(url => {
                    self.gravatarUrl(url);
                    self.hasGravatar(true);
                },
                error => self.hasGravatar(false));
        } else {
            self.hasGravatar(false);
        }
    };

    self.showGravatar();
}

ko.components.register('gravatar', {
    viewModel: GravatarViewModel,
    template: `
    <!-- ko if: hasGravatar() -->
    <div class="avatar" data-bind="attr: {'title': title}, style: { backgroundImage: 'url(\\'' + gravatarUrl() + '\\')' }"></div>
    <!-- /ko -->
    <!-- ko if: !hasGravatar() -->
    <div data-bind='component: {
        name: fallbackComponent,
        params: { name: name, title: title, email: email, login: login }
    }'></div>
    <!-- /ko -->
`
});

// Fallback components if gravatar is not found.
ko.components.register('initials', {
    viewModel: function (params) {
        this.title = params.title;
        this.name = params.name;
        this.initials = initials.get(params);
    },
    template: `
    <div class="avatar" data-bind="text: initials.initials,
                                   style: { backgroundColor: initials.color },
                                   css: { 'no-account-avatar': !name },
                                   attr: {'title': title}">
    </div>
    `
});

ko.components.register('headerIcon', {
    template: `
    <i class="icon icon-user"></i>
    `
});
