import { sentAnalyticsEvent } from '../../analytics';

ko.bindingHandlers.analytics = {
    init: function (element, valueAccessor, allBindings) {
        const value = valueAccessor();
        const triggerEvent = value.event || 'click';
        $(element).on(triggerEvent, eventHandler);
        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            $(element).off(triggerEvent, eventHandler);
        });
        const action = ko.utils.unwrapObservable(value.action);
        value.action = Settings.STORAGE_ACTIONS[action] || action;
        function eventHandler (event) {
            const gaObj = (typeof value === 'object' && !(value instanceof Array));
            if (gaObj && value.action) {
                sentAnalyticsEvent(value, { event });
            }
        }
    }
};
