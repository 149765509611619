import React from 'react';
import styled from 'styled-components';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { Dialog } from '@vectorworks/vcs-ui/dist/lib/Dialog/Dialog';
import { hoverAnimation, hoverListItem, linkNoDecoration } from '@vectorworks/vcs-ui/dist/lib/styles/common';

import { IntegrationArrow, ItemIcon, svgColors } from '../Main/styles';
import { Integration } from './integration';

const StyledItem = styled.a`
    display: flex;
    padding-bottom: 10px;

    ${svgColors}
    ${hoverListItem}
    ${linkNoDecoration()}
    ${hoverAnimation()}
`;

const Info = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;

    h1 {
        font-size: 14px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }

    span {
        font-size: 13px;
        color: var(--text-secondary-color);
        padding-right: 20px;
    }
`;

// eslint-disable-next-line react/prop-types
export function AddIntegrationDialog ({ dialog }) {
    // eslint-disable-next-line react/prop-types
    const onClose = () => dialog.dismiss();

    const renderIntegration = ({ storageType, integrationURL, svgHTML, displayName, description }) => {
        return (
            <StyledItem href={integrationURL} key={ storageType } data-what='provider' data-which={storageType.replace('_', '-')}>
                <ItemIcon>
                    { svgHTML }
                </ItemIcon>
                <Info>
                    <h1>
                        {displayName}
                        <IntegrationArrow>
                            <Icon icon='right-arrow' size='sm' />
                        </IntegrationArrow>
                    </h1>
                    <span>{description}</span>
                </Info>

            </StyledItem>
        );
    };

    return (
        <Dialog size='sm'>
            <Dialog.Header title={ gettext('Add integration') } onClose={onClose} />
            <Dialog.Inner>
                {
                    Object.values(Integration.ALL)
                        .map(integration => renderIntegration(integration))
                }
            </Dialog.Inner>
        </Dialog>
    );
}
