ko.bindingHandlers.defaultImage = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        const image = element;
        const defaultSrc = ko.unwrap(valueAccessor());

        // the image is already loaded
        if (image.complete) {
            if (image.naturalWidth === 0 || image.naturalHeight === 0) {
                // failed to load
                $(image).attr('src', defaultSrc);
            }
            return;
        }

        $(image).on('error', onSrcError);
        $(image).on('load', onSrcOk);

        function onSrcError (e) {
            if ($(image).attr('src') !== defaultSrc) {
                $(image).attr('src', defaultSrc);
            }
            $(element).unbind();
        }

        function onSrcOk () {
            $(element).unbind();
        }

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            $(element).unbind();
        });
    }
};
