import api from '../../../asset/api';
import { FilePath } from '../../../lib/file';
import { Dialog } from '../../../base/dialog';

const MAX_SIZE_FOLDER = 21474836480; // 20GB

const readcontents = function (items, validateCallback, symbolInvalidCallback, sizeInvalidCallback) {
    for (let i = 0; i < items.length; i++) {
        const item = items[i].webkitGetAsEntry();
        if (item.isFile) {
            items[i].getAsFile().size > MAX_SIZE_FOLDER
                ? sizeInvalidCallback(item)
                : FilePath(item.fullPath).isAllowedDirectoryPath()
                    ? validateCallback(item)
                    : symbolInvalidCallback(item);
        }
        if (item.isDirectory) {
            if (FilePath(item.fullPath).isAllowedDirectoryPath()) {
                const validEntries = [];
                let size = 0;
                function read(reader) { // eslint-disable-line
                    const subDirs = [];
                    reader.readEntries(function (entries) {
                        for (const entry of entries) {
                            if (entry.isDirectory) {
                                if (FilePath(entry.fullPath).isAllowedDirectoryPath()) {
                                    subDirs.push(entry);
                                } else {
                                    symbolInvalidCallback(entry, false);
                                }
                            } else if (FilePath(entry.fullPath).isAllowedDirectoryPath()) {
                                entry.getMetadata(metadata => {
                                    size += metadata.size;
                                });
                                size += entry.size;
                                validEntries.push(entry);
                            } else {
                                symbolInvalidCallback(item, false);
                            }
                        }
                        if (!subDirs.length && size > MAX_SIZE_FOLDER) {
                            sizeInvalidCallback(item);
                        } else if (!subDirs.length) {
                            validateCallback(validEntries);
                        }
                        if (subDirs.length) {
                            read(subDirs.pop().createReader());
                        }
                    });
                };
                read(item.createReader());
            }
        }
    }
};

ko.bindingHandlers.dragAndDropOnAsset = Settings.NEW_FEATURES.isActive('folder_upload')
    ? {
        init: function (element, valueAccessor, allBindings, asset, bindingContext) {
            const homeView = asset.assets.view;
            const isAdvancedUpload = function () {
                const div = document.createElement('div');
                return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
            };
            if (!(isAdvancedUpload && asset.assets.view.uploadEnabled)) {
                return;
            }

            const handleDropEvent = e => {
                e.preventDefault();
                homeView.dropUpload.drop(false);

                if (asset.isFolder) {
                    const items = Array.from(e.originalEvent.dataTransfer.items);
                    const supportsDataTransfer = e.originalEvent.dataTransfer !== null && e.originalEvent.dataTransfer.items;
                    let filesForUpload = [];
                    let itemValidPaths = [];
                    let itemInvalidPaths = [];
                    const itemConflictPaths = [];
                    const itemOverwritePaths = [];
                    const itemTooLargeSizePaths = [];
                    let callbackInvocations = 0;

                    if (supportsDataTransfer && Settings.NEW_FEATURES.isActive('folder_upload')) {
                        const addForUpload = (file, dir) => {
                            file.fileDirectory = dir;
                            file.dir = dir;
                            filesForUpload.push(file);
                        };

                        const addToSymbolInvalid = (item, isItem = true) => {
                            itemInvalidPaths.push(item);
                            if (isItem) {
                                callbackInvocations++;
                            }
                            if (callbackInvocations === items.length) {
                                validatePaths(itemValidPaths);
                            }
                        };

                        const addToSizeInvalid = (item) => {
                            itemTooLargeSizePaths.push(item);
                            callbackInvocations++;
                            if (callbackInvocations === items.length) {
                                validatePaths(itemValidPaths);
                            }
                        };

                        const uploadFiles = (files) => {
                            files.forEach(f => {
                                f.file(file => {
                                    f.getParent(p => {
                                        homeView.startDragDropFolderUpload(
                                            [file],
                                            `/${asset.name}${p.fullPath}`
                                        );
                                    });
                                });
                            });
                        };

                        const addToValid = (item) => {
                            itemValidPaths = itemValidPaths.concat(item);
                            callbackInvocations++;
                            if (callbackInvocations === items.length) {
                                validatePaths(itemValidPaths);
                            }
                        };

                        const validatePaths = (items) => {
                            const paths = items.map(i => homeView.currentFolder() === ''
                                ? `${asset.name}${i.fullPath}`
                                : `${homeView.currentFolder()}/${asset.name}${i.fullPath}`);
                            api.getShallowFiles(paths, asset.owner, asset.storageProvider.storageType)
                                .then(res => {
                                    if (res.conflict) {
                                        items.forEach(i => {
                                            res.conflict.forEach(res => {
                                                const path = homeView.currentFolder() === ''
                                                    ? '/' + res
                                                    : res.slice(homeView.currentFolder().length);
                                                path.includes(i.fullPath) && itemConflictPaths.push(i);
                                            });
                                        });
                                    }
                                    if (res.overwrite) {
                                        items.forEach(i => {
                                            res.overwrite.forEach(res => {
                                                const path = homeView.currentFolder() === ''
                                                    ? '/' + res
                                                    : res.slice(homeView.currentFolder().length);
                                                path.includes(i.fullPath) && itemOverwritePaths.push(i);
                                            });
                                        });
                                    }
                                    if (res.valid) {
                                        items.forEach(i => {
                                            res.valid.forEach(res => {
                                                const path = homeView.currentFolder() === ''
                                                    ? '/' + res
                                                    : res.slice(homeView.currentFolder().length);
                                                path.includes(i.fullPath) &&
                                                    addForUpload(i, FilePath(i.fullPath).folder());
                                            });
                                        });
                                    }
                                    initiateUpload();
                                });
                        };

                        readcontents(items, addToValid, addToSymbolInvalid, addToSizeInvalid);

                        const overwriteDialog = () => {
                            return Dialog.open({
                                component: 'dialog-overwrite-paths',
                                ctx: {
                                    ctx: { items: itemOverwritePaths }
                                }
                            });
                        };

                        const conflictDialog = () => {
                            return Dialog.open({
                                component: 'alert',
                                ctx: {
                                    ctx: { items: itemConflictPaths },
                                    template: { name: 'dialog-conflict-paths' }
                                }
                            });
                        };

                        const invalidDialog = () => {
                            return Dialog.open({
                                component: 'alert',
                                ctx: {
                                    ctx: { items: itemInvalidPaths },
                                    template: { name: 'dialog-invalid-paths' }
                                }
                            });
                        };

                        const sizeDialog = () => {
                            return Dialog.open({
                                component: 'alert',
                                ctx: {
                                    ctx: { items: itemTooLargeSizePaths },
                                    template: { name: 'dialog-folder-too-large' }
                                }
                            });
                        };

                        const initiateUpload = () => {
                            $(element).toggleClass('list-drop-hover');
                            itemInvalidPaths = Array.from(new Set(itemInvalidPaths));
                            const dialogTuples = [
                                [itemConflictPaths, conflictDialog],
                                [itemInvalidPaths, invalidDialog],
                                [itemTooLargeSizePaths, sizeDialog]
                            ];
                            let currentPromise;
                            dialogTuples.forEach(tuple => {
                                if (tuple[0].length) {
                                    if (currentPromise) {
                                        currentPromise = currentPromise.finally(() => {
                                            return tuple[1]();
                                        });
                                    } else {
                                        currentPromise = tuple[1]().result;
                                    }
                                }
                            });
                            if (itemOverwritePaths.length) {
                                if (currentPromise) {
                                    currentPromise.finally(() => {
                                        overwriteDialog().result.then(() => {
                                            filesForUpload = filesForUpload.concat(itemOverwritePaths);
                                            uploadFiles(filesForUpload);
                                        }).catch(() => {
                                            uploadFiles(filesForUpload);
                                        });
                                    });
                                } else {
                                    overwriteDialog().result.then(() => {
                                        filesForUpload = filesForUpload.concat(itemOverwritePaths);
                                        uploadFiles(filesForUpload);
                                    }).catch(() => {
                                        uploadFiles(filesForUpload);
                                    });
                                }
                            } else {
                                uploadFiles(filesForUpload);
                            }
                        };
                    }
                }
            };

            $(element)
                .on('drop dropend dragover dragend', e => {
                    e.preventDefault();
                })
                .on('dragenter', e => {
                    e.preventDefault();
                    if (Settings.NEW_FEATURES.isActive('folder_upload')) {
                        if (asset.isFolder) {
                            homeView.dropUpload.inCurrentFolder(false);
                            homeView.dropUpload.folder(asset.name);
                            $(element).toggleClass('list-drop-hover');
                        } else {
                            homeView.dropUpload.inCurrentFolder(true);
                            homeView.dropUpload.folder(
                                homeView.currentFolder() !== ''
                                    ? homeView.currentFolder()
                                    : gettext('Home')
                            );
                        }
                    }
                })
                .on('dragleave', e => {
                    e.preventDefault();
                    if (Settings.NEW_FEATURES.isActive('folder_upload')) {
                        if (asset.isFolder) {
                            $(element).toggleClass('list-drop-hover');
                        } else {
                            homeView.dropUpload.inCurrentFolder() && homeView.dropUpload.folder(
                                homeView.currentFolder() !== ''
                                    ? homeView.currentFolder()
                                    : gettext('Home')
                            );
                        }
                    }
                })
                .on('drop dragend', handleDropEvent);

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                // remove listeners
                $(element).unbind();
            });
        }
    }
    : {};
