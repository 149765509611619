import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { SidebarItem } from '../Main/Sidebar';

import { loadIntegrations } from './api';
import { Integration } from './integration';
import { AddIntegrationDialog } from './AddIntegrationDialog';

export function Integrations ({ isSelected, ...rest }) {
    const [integrations, setIntegrations] = useState([]);

    useEffect(() => {
        loadIntegrations().then(integrations => {
            const providerKeys = Object.keys(Integration.ALL);

            setIntegrations(
                integrations
                    .filter(i => providerKeys.includes(i.provider))
                    .map(i => Integration.ALL[i.provider])
            );
        });
    }, []);

    const openIntegrationsDialog = () =>
        window.ReactDialogs.open({ component: AddIntegrationDialog })
            .result.catch(console.log);

    return (
        <div { ...rest }>
            { integrations.map(({ displayName, url, svgHTML, storageType }) => (
                <SidebarItem
                    title={displayName}
                    svgHTML={svgHTML}
                    href={url}
                    key={storageType}
                    isSelected={
                        isSelected('homepage', storageType) ||
                        isSelected('google-pages', storageType) ||
                        isSelected('onedrive-pages', storageType)
                    }
                    data-what='sidebar-item'
                    data-which={storageType.replace('_', '-')}
                />
            ))}

            <SidebarItem
                title={ gettext('Add integration') }
                icon='plus'
                onClick={ openIntegrationsDialog }
                isSelected={false}
                data-what='sidebar-item'
                data-which='add-integration'
            />
        </div>
    );
}

Integrations.propTypes = {
    isSelected: PropTypes.func
};
