ko.bindingHandlers.setFocus = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        $(element).focus();

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            // remove listeners
            $(element).unbind();
        });
    }
};

ko.bindingHandlers.setFocusToId = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        const config = valueAccessor();

        $(element).on('click', function (event) {
            event.preventDefault();
            $(config.target).focus();
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            // remove listeners
            $(element).unbind();
        });
    }
};
