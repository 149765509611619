const hasTouchEvents = $.isTouchCapable();
ko.bindingHandlers.showContextMenu = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        const parent = bindingContext.$parent.items;
        if (hasTouchEvents) {
            $(element).on('touchstart', function (event) {
                parent.isTouchEvent(true);
            });
            $(element).on('touchend', function (event) {
                parent.isTouchEvent(false);
            });
            $(element).on('contextmenu', function (event) {
                if (parent.isTouchEvent()) {
                    event.preventDefault();
                    event.stopImmediatePropagation();
                    return false;
                }
                valueAccessor()(viewModel, event);
                return true;
            });
            $(element).taphold(function (event) {
                parent.holdSelect(viewModel, event);
            });
        }

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            // remove listeners
            $(element).unbind();
        });
    }
};
