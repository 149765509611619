ko.bindingHandlers.expandLeft = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        const elem = $(element);

        $(document).on('click', () => {
            elem.removeClass('on-hover');
        });

        elem.on('click', (e) => {
            e.stopPropagation();
            elem.toggleClass('on-hover');
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            // remove listeners
            elem.unbind();
        });
    }
};
