ko.bindingHandlers.selectedVersion = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        console.log(valueAccessor);
        console.log(viewModel);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            // remove listeners
            $(element).unbind();
        });
    }
};
