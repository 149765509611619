import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';

import { Overlay, StyledItem, ItemTitle, ItemIcon, StyledSidebar } from './styles';

import { Integrations } from '../Integrations/Integrations';
import Storage from '../Storage/Storage';
import Toggler from '../Toggler/Toggler';
import VerticalFooter from '../VerticalFooter/VerticalFooter';

// TODO: Analytics

export function SidebarItem ({
    href,
    title,
    icon,
    svgHTML,
    isSelected,
    onClick,
    children,
    ...rest
}) {
    const handleClick = () => {
        onClick && onClick();
        return true; // Prevent nav on same link
    };

    return (
        <StyledItem
            isSelected={ isSelected }
            href={href}
            onClick={handleClick}
            title={ title }
            {...rest}
        >
            <ItemIcon>
                {icon && <Icon icon={icon} />}
                {svgHTML || null}
            </ItemIcon>
            <ItemTitle>{title}</ItemTitle>
            {children}
        </StyledItem>
    );
}

SidebarItem.propTypes = {
    href: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
    svgHTML: PropTypes.any,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func
};
export function Sidebar ({ context, user }) {
    const isSelected = context.browserPage().is.bind(context.browserPage());
    const [open, setOpen] = useState(false);

    return Settings.user.isAuthenticated
        ? (
            <React.Fragment>
                <Toggler onClick={() => setOpen(!open)} />

                <StyledSidebar open={open} id='sidebar--main'>
                    {open && <Overlay onClick={() => setOpen(false)} />}

                    <SidebarItem
                        title={ gettext('Home') }
                        icon='home'
                        href='/portal/files/'
                        isSelected={isSelected('homepage', 's3')}
                        data-what='sidebar-item'
                        data-which='home'
                    />

                    <SidebarItem
                        title={ gettext('Shared with me') }
                        icon='shared-with-me'
                        href='/portal/files/shared_with_me/'
                        isSelected={isSelected('shared-with-me')}
                        data-what='sidebar-item'
                        data-which='shared-with-me'
                    />

                    <SidebarItem
                        title={ gettext('Shared') }
                        icon='share'
                        href='/portal/files/shared/'
                        isSelected={isSelected('shared')}
                        data-what='sidebar-item'
                        data-which='shared'
                    />

                    <SidebarItem
                        title={ gettext('Trash') }
                        icon='trash'
                        href='/portal/files/trash/'
                        isSelected={isSelected('trash')}
                        data-what='sidebar-item'
                        data-which='trash'
                    />

                    <SidebarItem
                        title={ gettext('Auto processing') }
                        icon='auto-processing'
                        href='/portal/task/'
                        isSelected={isSelected('task')}
                        data-what='sidebar-item'
                        data-which='auto-processing'
                    />

                    <SidebarItem
                        title={ gettext('My presentations') }
                        icon='my-presentations'
                        href='/portal/presentations/'
                        isSelected={isSelected('presentations')}
                        data-what='sidebar-item'
                        data-which='presentations'
                    />

                    <Integrations
                        // eslint-disable-next-line react/jsx-no-bind
                        isSelected={isSelected}
                        style={{ marginTop: 20 }}
                    />

                    <Storage />
                    <VerticalFooter context={context} user={user} style={{ marginTop: 20 }}/>
                </StyledSidebar>
            </React.Fragment>
        )
        : null;
}

Sidebar.propTypes = {
    context: PropTypes.shape({
        browserPage: PropTypes.func,
        inFileBrowser: PropTypes.func
    }).isRequired,
    user: PropTypes.shape({
        isAuthenticated: PropTypes.bool,
        showPrivacyLink: PropTypes.bool
    }).isRequired
};
