import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { Wrapper, SocialIcons } from './styles';

export default function VerticalFooter ({ context, user, ...props }) {
    return (
        <Wrapper {...props}>
            {
                user.isAuthenticated && (
                    <li id='verticalFooter__browser'>
                        {
                            !context.inFileBrowser() &&
                            <a href='/portal/files/home'>{gettext('Home')}</a>
                        }
                        <a href='/portal/resources' data-what='gm-item' data-which='resources'>{gettext('Resources')}</a>
                        <a href='/products/' data-what='gm-item' data-which='products'>{gettext('Downloads')}</a>
                    </li>
                )
            }

            <li>
                <a
                    href='https://customers.vectorworks.net'
                    id='service_select_link'
                    ga-action='Bottom_Links'
                    ga-label='Return_to_Service_Select'
                    data-what='gm-item'
                    data-which='customers'
                >{gettext('Customer Portal')}
                </a>
            </li>

            <li>
                <a
                    href='http://www.vectorworks.net/company/legal'
                    id='legal_link'
                    ga-action='Bottom_Links'
                    ga-label='Legal'
                    data-what='gm-item'
                    data-which='legal'
                >{gettext('Legal')}
                </a>
                {
                    user.showPrivacyLink &&
                    <a
                        href='/privacy_statement'
                        id='privacy_link'
                        ga-action='Bottom_Links'
                        ga-label='Privacy'
                        data-what='gm-item'
                        data-which='privacy'
                    >{gettext('Privacy')}
                    </a>
                }
                <a
                    href='/termsofservice'
                    id='terms_of_service_link'
                    ga-action='Bottom_Links'
                    ga-label='Terms_of_Service'
                    data-what='gm-item'
                    data-which='terms-of-service'
                >{gettext('Terms of Service')}
                </a>
            </li>

            <li>
                <a
                    href='/products/'
                    ga-action='Bottom_Links'
                    ga-label='Products'
                    data-what='gm-item'
                    data-which='products'
                >{gettext('Products')}
                </a>

                <a
                    id='about_us_link'
                    href='/portal/aboutus/'
                    ga-action='Bottom_Links'
                    ga-label='About_Us'
                    data-what='gm-item'
                    data-which='about'
                >{gettext('About Us')}
                </a>

                <a
                    id='contact_link'
                    href='http://www.vectorworks.net/company/contacts'
                    ga-action='Bottom_Links'
                    ga-label='Contact'
                    data-what='gm-item'
                    data-which='contact'
                >{gettext('Contacts')}
                </a>

                <a
                    href='/portal/help/'
                    ga-action='Help'
                    ga-label='Click_Help'
                    data-what='gm-item'
                    data-which='help'
                >{gettext('Help')}
                </a>

                <a
                    href='/portal/support/'
                    ga-action='Help'
                    ga-label='Click_Support'
                    data-what='gm-item'
                    data-which='support'
                >{gettext('Support')}
                </a>

                <a
                    href='/portal/releasenotes/'
                    ga-action='Help'
                    ga-label='Click_Release_Notes'
                    data-what='gm-item'
                    data-which='release-notes'
                >{gettext('Release Notes')}
                </a>
            </li>

            <li>
                <SocialIcons>
                    <a href={gettext('https://www.facebook.com/Vectorworks')} target='_blank' rel='noreferrer' data-what='gm-item-social' data-which='facebook'>
                        <Icon icon='fb-circ' size='sm' />
                    </a>
                    <a href={gettext('https://twitter.com/vectorworks')} target='_blank' rel='noreferrer' data-what='gm-item-social' data-which='twitter'>
                        <Icon icon='twitter-circ' size='sm' />
                    </a>
                    <a href={gettext('https://instagram.com/vectorworks/?hl=en')} target='_blank' rel='noreferrer' data-what='gm-item-social' data-which='instagram'>
                        <Icon icon='insta-circ' size='sm' />
                    </a>
                    <a href={gettext('https://www.linkedin.com/company/vectorworks-inc')} target='_blank' rel='noreferrer' data-what='gm-item-social' data-which='linkedin'>
                        <Icon icon='linkedin-circ' size='sm' />
                    </a>
                    <a href={gettext('https://www.youtube.com/user/vectorworks')} target='_blank' rel='noreferrer' data-what='gm-item-social' data-which='youtube'>
                        <Icon icon='yt-circ' size='sm' />
                    </a>
                    <a href={gettext('http://planet.vectorworks.net/')} target='_blank' rel='noreferrer' data-what='gm-item-social' data-which='planet'>
                        <Icon icon='rss-circ' size='sm' />
                    </a>
                </SocialIcons>
            </li>
        </Wrapper>
    );
}

VerticalFooter.propTypes = {
    context: PropTypes.shape({
        browserPage: PropTypes.func,
        inFileBrowser: PropTypes.func
    }).isRequired,
    user: PropTypes.shape({
        isAuthenticated: PropTypes.bool,
        showPrivacyLink: PropTypes.bool
    }).isRequired
};
