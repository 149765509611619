ko.bindingHandlers.toggleClass = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        const config = valueAccessor();

        $(element).on('click', function (event) {
            event.preventDefault();
            $(config.target).toggleClass(config.klass);
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            $(element).unbind();
        });
    }
};
