import pubsub from './pubsub';

const cache = new Map();
const observedKeys = new Set();

window.ko.extenders.storage = function (target, settings) {
    const { key, defaultVal = null } = settings;
    target.subscribe(function (newValue) {
        if (storageGet(key) !== newValue) {
            storageSet(key, newValue);
        }
    });
    target.subscribeTo(`storage.${key}`);
    target(storageGet(key, defaultVal));
    observedKeys.add(key);
    return target;
};

const withUserPrefix = key => `${Settings.user.login}.${key}`;

function storageGet (key, defaultVal = null) {
    if (!cache.has(key)) {
        cache.set(key, window.store.get(withUserPrefix(key), defaultVal));
    }
    return cache.get(key);
}

function storageSet (key, value) {
    cache.set(key, value);
    window.store.set(withUserPrefix(key), value);
    if (observedKeys.has(key)) {
        pubsub.publish(`storage.${key}`, value);
    }
}

export {
    storageGet as get,
    storageSet as set
};
