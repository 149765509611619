ko.bindingHandlers.collapse = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        const collapsed = valueAccessor();
        const initialValue = collapsed();
        initialValue ? $(element).collapse('show') : $(element).collapse('hide');

        collapsed.subscribe(value => {
            value ? $(element).collapse('show') : $(element).collapse('hide');
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            // remove listeners
            $(element).unbind();
        });
    }
};
