const djangoTemplateLoader = {
    loadTemplate: function (name, templateConfig, callback) {
        if (templateConfig.url) {
            $.ajax({
                url: Settings.PORTAL_PREFIX + 'template/' + templateConfig.url.replace(/\/$/, '') + '/',
                async: false,
                dataType: 'html',
                success: function (data) {
                    callback(ko.utils.parseHtmlFragment(data));
                }
            });
        } else {
            callback(null);
        }
    }
};

ko.components.loaders.unshift(djangoTemplateLoader);
