import { Dialog } from './dialog';

$(document).ajaxError((ev, jqxhr) => {
    if (jqxhr.status === 401 || jqxhr.status === 428) {
        if (jqxhr.responseJSON && jqxhr.responseJSON.status === 'wrong-login-data') {
            return;
        }

        if (jqxhr.responseText === 'Credentials expired or revoked.') {
            // Expired storage credentials are handled
            Dialog.open({
                component: 'dialog-integration-expired-revoked',
                ctx: {
                    template: { name: 'dialog-integration-expired-revoked' }
                }
            });
            return;
        }
        const { pathname, search, hash } = window.location;
        const next = window.encodeURI(pathname + search + hash);
        Dialog.open({
            component: 'alert',
            ctx: {
                ctx: { loginUrl: `/accounts/login/?next=${next}` },
                template: { name: 'url:browser/dialogs/dialog-unauthorized-error.html' }
            }
        });
    }
});
