ko.bindingHandlers.openLink = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        const href = ko.utils.unwrapObservable(valueAccessor()).href;

        $(element).on('click', () => {
            window.location.assign(href);
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            // remove listeners
            $(element).unbind();
        });
    }
};
