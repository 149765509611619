ko.bindingHandlers.trimText = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        const trimmedText = ko.computed(function () {
            const untrimmedText = ko.utils.unwrapObservable(valueAccessor());
            return getTrimmedText(untrimmedText, ko.utils.unwrapObservable(allBindings().trimTextLength));
        });
        ko.applyBindingsToNode(element, {
            text: trimmedText
        }, viewModel);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            // remove listeners
            $(element).unbind();
        });

        return {
            controlsDescendantBindings: true
        };
    },
    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    }
};

function getTrimmedText (txt, max) {
    const defaultMaxLength = 25;
    const minLength = 7;

    let maxLength = max || defaultMaxLength;
    if (maxLength < minLength) maxLength = minLength;

    const untrimmedTextLength = txt.length;
    if (untrimmedTextLength <= maxLength) {
        return txt;
    }
    const firstStop = maxLength / 2 - 1;
    const secondStop = untrimmedTextLength - maxLength / 2;
    return txt.substring(0, firstStop) + '...' + txt.substring(secondStop, untrimmedTextLength);
}
