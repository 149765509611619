ko.bindingHandlers.openDialog = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        const component = ko.utils.unwrapObservable(valueAccessor()).component;

        $(element).on('click', () => {
            /* eslint-disable */
            Dialog.open({ component: component });
            /* eslint-enable */
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            // remove listeners
            $(element).unbind();
        });
    }
};
