import keyboard from '../../keyboard';

/*
    Binding to ensure that trying to select text in the input
    will not actually select assets.
*/

ko.bindingHandlers.inputSelect = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        $(element).on('focus', () => {
            keyboard.pushKeyEvents({
                cmmndA: {
                    predicate: event => (event.metaKey || event.ctrlKey) && (event.which === 65 || event.which === 97),
                    handler: event => event.target.select()
                }
            });
        });

        $(element).on('blur', keyboard.popKeyEvents);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            // remove listeners
            $(element).unbind();
        });
    }
};
