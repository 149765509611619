import { Dialog } from './dialog';
import { request } from './axios';

function FeedbackViewModel (params) {
    this.ctx = params.ctx;
    this.dialog = params.dialog;

    this.message = ko.observable().extend({
        required: {
            message: gettext('This field is required.'),
            params: true
        },
        maxLength: {
            message: gettext('The maximum length is 1000 characters.'),
            params: 1000
        }
    });

    this.form = ko.validatedObservable({
        message: this.message
    });

    this.isSent = ko.observable(false);
    this.hasError = ko.observable(false);
    this.isBeingSend = ko.observable(false);

    this.send = () => {
        this.isBeingSend(true);
        request({
            url: '/restapi/public/v1/feedback/',
            method: 'POST',
            data: {
                ...Object.fromEntries(
                    new URLSearchParams(
                        new FormData(document.querySelector('#feedback')) // eslint-disable-line
                    )
                ),
                vcs_version: Settings.VCS_VERSION
            },
            contentType: 'application/json; charset=utf-8'
        })
            .then(data => {
                this.isBeingSend(false);
                if (data.has_sent) {
                    this.isSent(true);
                } else {
                    this.hasError(true);
                }
            })
            .catch(() => {
                this.isBeingSend(false);
                this.hasError(true);
            });
    };

    this.dismiss = () => {
        this.isSent(false);
        this.dialog.dismiss();
    };

    this.init = () => {
        if (!Settings.user.isAuthenticated) {
            this.dialog.dismiss();
            Dialog.open({
                component: 'alert',
                ctx: {
                    ctx: { loginUrl: `/accounts/login/?next=${window.location.pathname}` },
                    template: { name: 'url:browser/dialogs/dialog-unauthorized-error.html' }
                }
            });
        }
    };

    this.init();
}

Dialog.register('dialog-feedback', {
    viewModel: FeedbackViewModel,
    template: { url: 'base/dialogs/dialog-feedback.html' }
});
