import styled from 'styled-components';

import { linkNoDecoration } from '@vectorworks/vcs-ui/dist/lib/styles/common';
import { respondTo } from '@vectorworks/vcs-ui/dist/lib/styles/responsive';

export const Wrapper = styled.div`
    ${respondTo('phone', 'display: flex;')}
    ${respondTo('tablet', 'display: flex;')}

    display: none;
    flex-direction: column;
    background-color: var(--grey8);
    padding: 0 15px;

    #verticalFooter__browser {
        ${respondTo('phone', 'display: block;')}
        ${respondTo('tablet', 'display: block;')}
        display: none;
    }

    #verticalFooter__help {
        ${respondTo('phone', 'display: block;')}
        display: none;
    }

    li {
        list-style-type: none;
        padding: 10px 0;
        border-bottom: 1px solid var(--grey7);
        font-size: 13px;
    }

    a {
        ${linkNoDecoration('white')}
        display: block;
        padding: 5px 0;
        font-size: 14px;
        text-transform: uppercase;
    }
`;

export const SocialIcons = styled.div`
    display: flex;
    
    a {
        padding: 0 3px;
    }
`;
