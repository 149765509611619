ko.bindingHandlers.datePicker = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        moment.locale(Settings.LANGUAGE_CODE);

        $(element).datepicker({
            format: {
                toDisplay: date => moment(date).utc().format(Settings.DATE_FORMAT),
                toValue: date => date
            },
            weekStart: 1,
            todayHighlight: true,
            startDate: moment().add(1, 'days').toDate(),
            orientation: 'bottom auto',
            language: Settings.LANGUAGE_CODE,
            autoclose: true
        });

        const datepicker = $('.datepicker-wrapper');
        const datepickerInput = $('.datepicker-input');
        datepicker.datepicker().on('hide', function () {
            if (datepickerInput.val() === '') {
                datepickerInput.val(moment().add(14, 'days').format(Settings.DATE_FORMAT));
            }
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            $(element).unbind();
            datepicker.unbind();
        });
    }
};
