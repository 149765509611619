import axios from 'axios';
import gravatarApi from 'gravatar-api';

import stringToColor from './string-to-color';

const gravatar = (() => {
    const NO_URL = 'no_url';
    const cache = new Map();
    const get = email => {
        const url = cache.get(email);
        return url
            ? (url !== NO_URL ? Promise.resolve(url) : Promise.reject(null))
            : new Promise((resolve, reject) => {
                const options = {
                    email,
                    type: 'json',
                    secure: true,
                    parameters: { size: '30', d: '404' }
                };

                const imageUrl = gravatarApi.imageUrl(options);

                axios.get(imageUrl)
                    .then(response => {
                        cache.set(email, imageUrl);
                        resolve(imageUrl);
                    })
                    .catch(error => {
                        cache.set(email, NO_URL);
                        reject(null);
                    });
            });
    };
    return { get };
})();

const initials = {
    get (params) {
        const name = params.name;
        const login = params.login;
        const email = params.email;

        return name && name !== 'null'
            ? {
                initials: name.split(' ').map((n) => n.substring(0, 1)).join(''),
                color: stringToColor(name + email + login)
            }
            : {
                initials: params.email ? params.email[0].toUpperCase() : '?',
                color: stringToColor()
            };
    }
};

export {
    gravatar,
    initials
};
