import { observable, makeObservable, runInAction } from 'mobx';
import { loadStorage } from '../Integrations/api';

const MB = 1024 * 1024;
const bytesToMB = size => Math.round(size / MB);

class StorageQuotaStore {
    storageQuota = null;
    storageUsed = null;

    constructor () {
        makeObservable(this, {
            storageUsed: observable
        });
    }

    refresh () {
        return loadStorage()
            .then(({ quotas }) => {
                runInAction(() => {
                    this.storageQuota = quotas.StorageQuota;
                    this.storageUsed = quotas.storage_used;
                });
                return quotas;
            });
    }

    haveEnoughSpace (size) {
        return bytesToMB(this.storageUsed + size) < this.storageQuota;
    }
}

const storageQuotaStore = new StorageQuotaStore();
window.Settings.user.isAuthenticated &&
    storageQuotaStore.refresh();

export default storageQuotaStore;
export { bytesToMB };
