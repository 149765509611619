// a templateSource that retrieves data via AJAX and provides loading/error templates
ko.UrlTemplate = function (url, loadingTmpl, errorTmpl) {
    this.url = url;
    this.loaded = false;
    this.inProgress = false;

    // templates to use while loading and when an error happens.  fall back to a default.
    this.loadingTmpl = loadingTmpl || this.loadingTmpl;
    this.errorTmpl = errorTmpl || this.errorTmpl;

    // the current template
    this.currentTmpl = ko.observable(this.loadingTmpl);
    this.currentTmpl.data = {}; // a place to tuck away meta-data about the template
};

ko.utils.extend(ko.UrlTemplate.prototype, {
// read/write meta-data about the template (has it been rewritten already; not used for native templates currently)
    data: function (key, value) {
        if (arguments.length === 1) {
            return this.currentTmpl.data[key];
        }
        this.currentTmpl.data[key] = value;
    },
    // read/write the actual template text
    text: function (value) {
        if (!this.loaded) {
            this.getTemplate();
        }

        if (arguments.length === 0) {
            return this.currentTmpl();
        } else {
            this.currentTmpl(arguments[0]);
        }
    },
    // retrieve our actual template via AJAX
    getTemplate: function (url) {
        if (!this.inProgress) {
            this.inProgress = true;
            $.ajax({
                url: Settings.PORTAL_PREFIX + 'template/' + this.url.replace(/\/$/, '') + '/',
                context: this,
                success: function (data) {
                    this.currentTmpl(data);
                    this.loaded = true;
                    this.inProgress = false;
                },
                error: function (data) {
                    this.currentTmpl(this.errorTmpl);
                    this.loaded = true;
                    this.inProgress = false;
                }
            });
        }
    },
    // some defaults to use for the loading/error templates
    loadingTmpl: '',
    errorTmpl: 'error!<br/>'
});

function UrlTemplateEngine () {
    const templates = {};
    const templateEngine = new ko.nativeTemplateEngine(); // eslint-disable-line new-cap
    const makeTemplateSourceOriginal = templateEngine.makeTemplateSource;
    templateEngine.makeTemplateSource = function (templateName) {
        if ($.type(templateName) === 'string' && templateName.startsWith('url:')) {
            if (!templates[templateName]) {
                templates[templateName] = new ko.UrlTemplate(templateName.substring('url:'.length));
            }
            return templates[templateName];
        }
        return makeTemplateSourceOriginal.call(this, templateName);
    };
    return templateEngine;
}

// make this new template engine our default engine
ko.setTemplateEngine(new UrlTemplateEngine());
