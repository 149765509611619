ko.bindingHandlers.removeTextSelect = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        $(element).on('selectstart', (e) => e.preventDefault());

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            // remove listeners
            $(element).unbind();
        });
    }
};
