ko.bindingHandlers.escapekey = {
    init: function (element, valueAccessor, allBindings, viewModel) {
        const callback = valueAccessor();
        $(element).keyup(function (event) {
            const keyCode = (event.which ? event.which : event.keyCode);
            if (keyCode === 27) {
                callback.call(viewModel);
                return false;
            }
            return true;
        });
    }
};
