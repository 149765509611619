import React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { respondTo } from '@vectorworks/vcs-ui/dist/lib/styles/responsive';

const StyledTogger = styled.div`
    ${respondTo('phone', css`
        display: flex;
        width: 56px;
        height: 56px;
    `)}

    ${respondTo('tablet', css`
        display: flex;
    `)}

    display: none;
    align-items: center;
    justify-content: center;

    height: 73px;
    width: 60px;

    background-color: var(--grey8);
    color: white;
`;

function Toggler (props) {
    return (
        <StyledTogger {...props}>
            <Icon icon='hamburger' />
        </StyledTogger>
    );
}

export default Toggler;
