import * as Sentry from '@sentry/browser';

if (Settings.SENTRY.dsn) {
    Sentry.init({
        dsn: Settings.SENTRY.dsn,
        release: Settings.VERSION,
        environment: Settings.SENTRY.environment
    });

    const user = Settings.user;
    user && Sentry.setUser({ username: user.login, email: user.email });
    window.Sentry = Sentry;
}
