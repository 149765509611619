ko.bindingHandlers.thumbnailLoader = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        const image = element;

        // the image is already loaded
        if (image.complete) {
            if (image.naturalWidth === 0 || image.naturalHeight === 0) {
                // failed to load
                viewModel.thumbnail = null;
            }
            return;
        }

        $(image).on('error', onSrcError);
        $(image).on('load', onSrcOk);

        function onSrcError (e) {
            viewModel.thumbnail = null;
            $(element).unbind();
        }

        function onSrcOk () {
            $(element).unbind();
        }

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            $(element).unbind();
        });
    }
};
