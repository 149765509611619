ko.bindingHandlers.fastclick = {
    init: function (element, valueAccessor, allBindings) {
        const callback = valueAccessor();

        // This timeout, started on mousedown, triggers the beginning of a hold
        let holdStarter = null;

        // This is how many milliseconds to wait before recognizing a hold
        const holdDelay = 500;

        // This flag indicates the user is currently holding the mouse down
        let holdActive = false;

        // MouseDown
        $(element).on('mousedown', onMouseDown);
        // MouseUp
        $(element).on('mouseup', onMouseUp);
        // OnClick
        // not using onclick at all - onmousedown and onmouseup take care of everything

        // Optional add-on: if mouse moves out, then release hold
        $(element).on('mouseout', onMouseOut);

        function onMouseDown () {
            // Do not take any immediate action - just set the holdStarter
            //  to wait for the predetermined delay, and then begin a hold
            holdStarter = setTimeout(function () {
                holdStarter = null;
                holdActive = true;
            }, holdDelay);
        }

        function onMouseUp () {
            // If the mouse is released immediately (i.e., a click), before the
            //  holdStarter runs, then cancel the holdStarter and do the click
            if (holdStarter) {
                clearTimeout(holdStarter);
                holdStarter = null;
                // run click-only operation here
                callback();
            } else if (holdActive) {
                // Otherwise, if the mouse was being held, end the hold
                holdActive = false;
            }
        }

        function onMouseOut () {
            holdActive = false;
            if (holdStarter) {
                clearTimeout(holdStarter);
                holdStarter = null;
            }
        }
    }
};
