ko.bindingHandlers.editable = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        const writeValue = () => {
            if (ko.isWriteableObservable(valueAccessor())) {
                valueAccessor()($(element).html());
            }
        };

        $(element).html(ko.utils.unwrapObservable(valueAccessor()));

        $(element).on('focus blur keyup paste input', () => {
            writeValue();
            return $(element);
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            // remove listeners
            $(element).unbind();
        });
    }
};
