import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@vectorworks/vcs-ui/dist/lib/Select/Select';
import { MenuItem } from '@vectorworks/vcs-ui/dist/lib/MenuItem/MenuItem';

const options = {
    FILES: {
        title: gettext('Files'),
        url: '/portal/files/trash/'
    },
    PRESENTATIONS: {
        title: gettext('Presentations'),
        url: '/portal/files/presentations-trash/'
    }
};

const TrashCategorySelect = (props) => {
    const { routerCtx } = props;
    return (
        <Select
            value={Object
                .values(options)
                .find(value => window.location.pathname.startsWith(value.url))}
            onChange={e => routerCtx.router.update(e.target.value.url)}
        >
            {
                Object
                    .entries(options)
                    .map(([key, value]) => <MenuItem key={key} value={value}>{value.title}</MenuItem>)
            }
        </Select>
    );
};

TrashCategorySelect.propTypes = {
    routerCtx: PropTypes.any
};

export default TrashCategorySelect;
