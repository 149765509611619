ko.bindingHandlers.preventReload = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        $(element).on('click', preventReload);
        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            $(element).off('click', preventReload);
        });

        function preventReload (event) {
            if ($(this).attr('href') === window.location.pathname) {
                event.preventDefault();
            }
            return true;
        }
    }
};
